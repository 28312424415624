const API_URL = 'https://puychereau.onrender.com';
//  const API_URL = 'http://localhost:4000';
export const API_ROUTES = {
SIGN_UP: `${API_URL}/api/auth/signup`,
SIGN_IN: `${API_URL}/api/auth/login`,
IMAGES: `${API_URL}/api/images`,
TEXT: `${API_URL}/api/text`,
EVENTS: `${API_URL}/api/events`,
PRESS: `${API_URL}/api/press`,
};

