import axios from 'axios'
import { API_ROUTES } from '../utils/constants';
import { useState } from 'react';
import '../pages-style/Sign.scss'

function Sign(){

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [message, setMessage] = useState('');


    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    
    /* const handleSignUp = (event) => {
        event.preventDefault(); // Empêche le comportement de soumission par défaut du formulaire

        // Envoyer les données d'inscription au backend
        axios.post(API_ROUTES.SIGN_UP, { username, password })
            .then(res => {
                // Traitement de la réponse en cas d'inscription réussie
                console.log('Utilisateur enregistré')
            })
            .catch(error => {
                // Traitement de l'erreur en cas de problème avec l'inscription
                return(error)
        })
    }; */

    const handleLogin = (event) => {
        event.preventDefault(); // Empêche le comportement de soumission par défaut du formulaire

        // Envoyer les données de connexion au backend
        axios.post(API_ROUTES.SIGN_IN, { username, password })
        .then(res => {
            // Traitement de la réponse en cas de connexion réussie
            sessionStorage.setItem('token', res.data.token);
            sessionStorage.setItem('userId', res.data.userId)
            window.location = '/'
        })
        .catch(error => {
            // Annuler le délai en cas d'erreur

            // Traitement de l'erreur en cas de problème avec la connexion
            console.error(error);
            setMessage(error)
        })
    };

    return(
        <div className="sign">
            <form >
                <div>{message}</div>
                <input type="text" placeholder="Nom d'utilisateur" name="username" value={username} onChange={handleUsernameChange}/>
                <input type="password" placeholder="Mot de passe" name="password" value={password} onChange={handlePasswordChange}/>
                <button onClick={handleLogin}>Connexion</button>
                {/* <button onClick={handleSignUp}>Inscription</button> */}
            </form>
        </div>
    )
}

export default Sign